import styled from "@emotion/styled/macro"
import { useLocation } from "@reach/router"
import * as Sentry from "@sentry/react"
import { navigate } from 'gatsby'
import React from 'react'
import { COLORS } from "../../../constants/colors"
import { getVideoEditRulesList } from "../../../constants/VideoEditRules.const"
import Asset from '../../client/asset'
import EditRules from "../../client/editRules"
import Sequence, { Chapter } from '../../client/sequence'
import VideoType from '../../client/videoType'
import { AppContext } from '../../lib/app-context'
import { removeChapterCuePoints } from "../../state/cuePoints"
import { handleError } from '../../state/error'
import { prependSequences, sequencesSinceLastChargeState } from '../../state/local'
import { removeScene, resetSequenceLiveState, scenesState, sequenceSave, sequenceState, setScene } from '../../state/sequence'
import Button, { ButtonSize, ButtonVariant } from '../../ui-components/Button'
import Loader from "../../ui-components/Loader"
import { trackEvent } from '../../utils/analityics.utils'
import ContentEditor from '../content-editor'
import Calibration from './Calibration'
import CreateProjectStepper from './CreateProjectStepper'
import MaterialsChaptersAndRatio from './MaterialsChaptersAndRatio'
import MaterialsLibrary from './MaterialsLibrary'
import {getDefaultCaptionsStyle} from "../../utils/closeCpation.utils";

const CreateContainer = styled.div`
    width: 100%;
    height: calc(100vh - 56px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

`

const HeaderWrapper = styled.div`
    height: 102px;
    width: 100%;
    padding: 28px 36px 10px 36px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    color: ${COLORS.peech_gray_900};
    z-index: 30;
`
const HeaderText = styled.div`
    width: 100%;
    height: fit-content;
    text-align: center;
`
const SubHeaderText = styled(HeaderText)`
    padding-top: 5px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
`

const MainBlock = styled.div`
    width: 100%;
    min-height: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .editor {
        background-color: ${COLORS.peech_gray_50};
    }
    .monitor {
        max-height: 52vh !important;
    }
    .editor--container {
        padding-top: 20vh;
    }
    .editor--preview {
        margin-top: 50px;
    }


`

const Footer = styled.div`
    height: 102px;
    width: 100%;
    padding: 20px 80px 30px 80px;
    display: flex;
    justify-content: flex-end;
`

const CancelBtn = styled.div` 
    height: 43px;
    width: 122px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${COLORS.peech_primary_2_500};
    cursor: pointer;
    :active {
        color: ${COLORS.peech_gray_400};
    }
`
const CentredDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`


export const MODES = {
    MATERIALS_LIBRARY: 0,
    MATERIALS_CHAPTERS_AND_RATIO: 1,
    VISUALS: 2,
    CONTENT: 3,
};


interface ProjectWizardProps {

}

export default function ProjectWizard({ }: ProjectWizardProps) {
    const [createFlowStep, setCreateFlowStep] = React.useState(MODES.MATERIALS_LIBRARY)
    const { user, plan, status, defaultVideoType, config } = React.useContext(AppContext)
    const sequence = sequenceState.use();
    const chapters = scenesState.use(scenes => scenes.filter.chapters)
    const [sorReady, setSorReady] = React.useState(false)
    const [titleReady, setTitleReady] = React.useState<boolean>(false)
    const [brandReady, setBrandReady] = React.useState(false)
    const [videoTypes, setVideoTypes] = React.useState(ProjectWizard.videoTypesSor);
    const [logoThumbnail, setLogoThumbnail] = React.useState(false);
    const [selectedAssets, setSelectedAssets] = React.useState<string[]>([]);
    const [shouldShowButtons, setShouldShowButtons] = React.useState(true);
    const [selectedType, setSelectedType] = React.useState(null);
    const [currentEditRules, setCurrentEditRules] = React.useState(null);
    const [sequenceRatio, setSequenceRatio] = React.useState('16:9');
    const [sequenceIsSaving, setSequenceIsSaving] = React.useState(false);
    const [sequenceTitle, setSequenceTitle] = React.useState('');
    const [skipContentStep, setSkipContentStep] = React.useState(false);
    const shouldSkipVisualsStep = config?.SHOULD_SKIP_SOR_ON_CREATE === 'true';

    const MAX_FILES_ALLOWED = !!user?.UIFLAGS.ALLOW_10_ASSETS_IN_PROJECT ? 10 : 5;
    console.log("MAX_FILES_ALLOWED", MAX_FILES_ALLOWED);
    

    const location = useLocation()
    const retryCount = React.useRef(-1)


    React.useEffect(() => {
        resetSequenceLiveState()
        loadVideoTypes()
    }, [])

    if (!user) {
        return null;
    }

    React.useEffect(() => {

        const intercomBoot = { 
            api_base: "https://api-iam.eu.intercom.io", 
            app_id: "m7k431u7",
            hide_default_launcher: true
         }

        if (!user) {
            return;
        }
        if (user?.preset?.assets?.find(a => a.type === Asset.TYPE.LOGO) || user.logoAssets?.length) {
            setLogoThumbnail(true);
        }
        window.Intercom && window.Intercom('shutdown')

        return () => {
            window.Intercom && window.Intercom('boot', intercomBoot);
        }
    }, [user, user?.preset])

    React.useEffect(() => {
        if ((user?.preset?.fontFamily || user?.preset?.hasFontAsset()) && logoThumbnail) {
            setBrandReady(true);
        }
    }, [user?.preset, logoThumbnail])

    // works when create project from Quick create button on assetPreview or after redirecting from onboarding
    React.useEffect(() => {
        const createNewSequence = async () => {
            if (location?.state?.asset) {
                if (location?.state?.skipContentStep) {
                    setSkipContentStep(true)
                }

                setCreateFlowStep(MODES.MATERIALS_CHAPTERS_AND_RATIO);
                try {
                    let title = location?.state?.asset?.values?.name;
                    if (title) {
                        //remove the  . + 3 letters or number from the end if exists
                        title = title.replace(/\.\w{1,3}$/, ''); 
                        //remove 1. from the begining of the title
                        title = title.replace(/^\d+\./, '');
                        setSequenceTitle(title);
                        await saveNewSequence();
                    }
                    addAsset(location.state.asset);
                    addSelectedAssets();
                } catch (err) {
                    console.log("addAsset error", err);
                }
                navigate('/project', { state: {}, replace: true })
            } else {
                await saveNewSequence();
            }
        }

        createNewSequence();

    }, [location?.state?.asset?.sid])



    async function loadVideoTypes() {
        if (location.hash || ProjectWizard.videoTypesSor.length) {
            return
        }
        try {
            const [types, editRules] = await Promise.all([
                VideoType.list(),
                EditRules.list()
            ]);
            ProjectWizard.videoTypesSor = types.sort((a, b) => a.order - b.order)
                .map(({ editRulesSid, ...rest }) => ({ ...rest, editRules: editRules.find(({ sid }) => sid === editRulesSid) }));
            setVideoTypes(ProjectWizard.videoTypesSor)
        }
        catch (err) {
            console.error('loadVideoTypes', err)
            retryCount.current++
            if (retryCount < 2) {
                return setTimeout(loadVideoTypes, 100)
            }
            handleError({
                title: 'Fetching video types failed',
                message: err.message,
                responseError: err
            })
        }
    }


    // function verifySequenceFonts() {
    //     if (!sequence.style.fontFamily && !sequence.style.bodyRegularFileName) {
    //         sequence.style.fontFamily = DEFAULT_FONT
    //     }
    //     if (!sequence.style.headerFontFamily && !sequence.style.headerRegularFileName) {
    //         sequence.style.fontFamily = DEFAULT_FONT
    //     }
    //     if (!sequence.style.captionsFontFamily && !sequence.style.captionsRegularFileName) {
    //         sequence.style.captionsFontFamily = DEFAULT_FONT
    //     }
    // }

    async function setSequenceAssets() {
        if (user.preset) {
            // @TODO enable once presetSid available on sequence
            sequence.presetSid = user.preset.sid
            await sequence.applyPreset(user.preset, user)
            // verifySequenceFonts()
            sequenceState.set(sequence)
        }
        return sequence
    }

    async function saveNewSequence() {
        await setSequenceAssets()
        const editRules = new EditRules();
        sequence.editRules = editRules;

        try {
            const newSequence = await sequenceSave(sequence)
            prependSequences(newSequence)
        } catch (err) {
            console.error('saveNewSequence error', err);
        }

        trackEvent('new-project', { action: 'start new project creating' });

        Sequence.setHeader('X-SequenceSid', sequence.sid)
        if (status && status.sid !== sequence.sid) {
            status.sid = sequence.sid;
        }

        prependSequences(sequence)
    }


    async function addAsset(asset) {
        const choosenAsset = new Asset().set(asset.values)
        let chapter = new Chapter()
        chapter.assetSid = choosenAsset.sid
        chapter.name = choosenAsset.name
        try {
            chapter = await sequence.addChapter(chapter)
            setScene(chapter, true);
        }
        catch (err) {
            return handleError({
                statusCode: 11,
                responseError: err
            });
        }
    }

    function onLanguageChange(value: string) {
        sequence.languageCode = value;
    }

    async function onTitleChange(title: string) {

        if (!title) {
            setSequenceTitle(null);
            setTitleReady(false);
        } else if (sequenceTitle !== title) {
            setSequenceTitle(title);
            return setTitleReady(true);
        }
    }

    function onEditRulesChange(videoType: VideoType) {
        if(!sequence?.sid){
            return;
        }
        sequence.editRulesSid = videoType?.values.editRulesSid || null;
        sequence.applyEditRules(videoType);
        setSorReady(true)
    }

    async function onDone() {
        setSequenceIsSaving(true);
        sequence.title = sequenceTitle;
        if (!sequence.title) {
            Sentry.captureException(new Error('title step click done sequence.title is null'));
            return;
        }
        if (!sequence.changedProperties.includes('title')) {
            Sentry.captureException(new Error('title property removed from changedProperties'));
            sequence.changedProperties.push('title');
        }
    
        saveDefaultVideoTypeInPreset();
        trackEvent('save-new-project', {
            action: {
                videoTypeSid: selectedType?.values?.sid,
                videoTypeName: sequence?.values?.editRules?.values?.name,
                editRules: sequence?.values?.editRules?.values,
                aspectRatio: sequence?.aspectRatio
            }
        });

        sequence.aspectRatio = sequenceRatio;

        const defaultCaptionsStyle = getDefaultCaptionsStyle(sequence)
        sequence.style!.captionsVerticalAlign = defaultCaptionsStyle.captionsVerticalAlign
        sequence.style!.captionsPosition = defaultCaptionsStyle.captionsPosition
        sequence.style!.captionsFontColor = defaultCaptionsStyle.captionsFontColor
        sequence.style!.captionsStyleType = defaultCaptionsStyle.captionsStyleType
        sequence.style!.captionsStyleColor = defaultCaptionsStyle.captionsStyleColor

        try {
            await sequenceSave(sequence);
            setSequenceIsSaving(false);
        } catch (error) {
            console.log('saving sequencw error', error);
            setSequenceIsSaving(false);
        }

        // call validate inputs before content editor
        // to validate that 1 or more file has verbs
    }

    function saveDefaultVideoTypeInPreset() {
        let preset = user.preset;
        if (preset) {
            try {
                preset.defaultVideoType = selectedType?.values?.sid;
                preset.save();
            } catch (error) {
                console.error('saveDefaultVideoTypeInPreset error: ', error);
            }
        }
    }


    function canProgressToNextStep() {
        if (createFlowStep === MODES.MATERIALS_LIBRARY) {
            return selectedAssets.length;
        }
        if (createFlowStep === MODES.MATERIALS_CHAPTERS_AND_RATIO) {
            return chapters.length && sequenceTitle?.length && sequenceRatio
        }
        if (createFlowStep === MODES.VISUALS) {
            return chapters.length > 0 && sequenceTitle?.length && sequence.editRulesSid && !sequenceIsSaving
        }
    }


    const stepsDictionary = React.useMemo(() => {

        let arr = [
                {
                    name: 'Footage',
                    headerText: 'Let’s start with choosing the footage in your video',
                    subHeaderText: `Select a maximum of ${MAX_FILES_ALLOWED} videos, and they will be seamlessly combined into a single video`,
                },
                {
                    name: 'Footage',
                    headerText: 'Give your video a title and choose its size',
                    subHeaderText:  'Peech will automatically resize and crop your video based on the speakers location',
                },
                {
                    name: !shouldSkipVisualsStep ? 'Visuals' : '',
                    headerText: !shouldSkipVisualsStep ?  'Great, now which type of video are you creating?' : '',
                    subHeaderText: !shouldSkipVisualsStep ?  'Each type has a different editing style based on thousands of other videos' : '',
                },
                {
                    name: 'Content',
                    headerText: 'Almost ready! Now just edit your content',
                    subHeaderText: 'By highlighting the text you can cut out pieces of your video'
                },

            ]

        return arr;

    }, [selectedAssets, chapters]);

    const changeStep = async () => {
        if (createFlowStep === MODES.MATERIALS_LIBRARY) {
            addSelectedAssets();
        }
        if (!shouldSkipVisualsStep && createFlowStep === MODES.MATERIALS_CHAPTERS_AND_RATIO) {
            trackEvent('create-nameRatio-complete');
        }
        if ((shouldSkipVisualsStep && createFlowStep === MODES.MATERIALS_CHAPTERS_AND_RATIO) || createFlowStep === MODES.VISUALS) {
            await onDone();
            setShouldShowButtons(false);
        }

        let counter = 1;
        if(shouldSkipVisualsStep && createFlowStep === MODES.MATERIALS_CHAPTERS_AND_RATIO) {
            counter = 2;
        }
        setCreateFlowStep(createFlowStep + counter);
    }


    function onAssetSelected(e, asset) {
        e.preventDefault()
        const assetIndex = selectedAssets.findIndex(a => a.sid === asset.sid)
        if (assetIndex > -1) {
            const newArray = selectedAssets.filter(a => a.sid !== asset.sid)
            setSelectedAssets(newArray)
        } else if (selectedAssets.length < MAX_FILES_ALLOWED) {
            setSelectedAssets([...selectedAssets, asset])

        }
    }

    async function addSelectedAssets() {
        for (const asset of selectedAssets) {
            let saved = chapters.find(el => el.assetSid === asset.sid);
            !saved && await addAsset(asset);
        }
        setSelectedAssets([]);
    }

    async function onChapterRemove(sid: string) {
        removeChapterCuePoints(sid)
        sequence.deleteChapter(sid);
        removeScene(sid, true);
        trackEvent('scene-delete');
        setSelectedAssets(prev => {
            let newState = [...prev].filter(el => el.sid !== sid);
            return newState;
        })
    }

    React.useEffect(() => {
        if (user && sequence?.sid && videoTypes && !selectedType) {
           
           const userDefaultVideoType = videoTypes.find(t => t.values.sid === user?.preset?.values?.defaultVideoType);
            if (!shouldSkipVisualsStep && userDefaultVideoType) {
                setSelectedType(userDefaultVideoType);
            } else if (defaultVideoType) {
                let type = videoTypes.find(t => t.values.sid === defaultVideoType.values.sid);
                setSelectedType(type || [...videoTypes][0]);
            } else {
                setSelectedType([...videoTypes][0]);
            }
        }
    }, [user, videoTypes, defaultVideoType, sequence?.sid]);

    React.useEffect(() => {
        if (sequence?.sid && selectedType && selectedType.editRules) {
            const editRulesCopy = { ...selectedType.editRules.values }
            let sors = {...getVideoEditRulesList(user)};
            for (let key in sors) {
                if (sors[key] && !sors[key].disabled) {
                    sors[key].isSelected = editRulesCopy[key];
                    sors[key].name = key;
                }
            }
            setCurrentEditRules(sors);
            onEditRulesChange(selectedType);
        }
    }, [selectedType])




    return (
        <CreateContainer >
            <CreateProjectStepper createFlowStep={createFlowStep} changeStep={changeStep} stepsDictionary={stepsDictionary} />
            <HeaderWrapper>
                <HeaderText>
                    {stepsDictionary[createFlowStep].headerText}
                </HeaderText>
                <SubHeaderText>
                    {/* {getSubHeaderText()} */}
                    {stepsDictionary[createFlowStep].subHeaderText}
                </SubHeaderText>

            </HeaderWrapper>
            <MainBlock>
                {createFlowStep === MODES.MATERIALS_LIBRARY && <MaterialsLibrary onAssetSelected={onAssetSelected} addSelectedAssets={addSelectedAssets} selectedAssets={selectedAssets} />}
                {createFlowStep === MODES.MATERIALS_CHAPTERS_AND_RATIO && <MaterialsChaptersAndRatio onChapterRemove={onChapterRemove} sequenceTitle={sequenceTitle} onTitleChange={onTitleChange} setCreateFlowStep={setCreateFlowStep} sequenceRatio={sequenceRatio} setSequenceRatio={setSequenceRatio} />}
                {createFlowStep === MODES.VISUALS && <Calibration videoTypes={videoTypes} selectedType={selectedType} setSelectedType={setSelectedType} currentEditRules={currentEditRules} sequenceIsSaving={sequenceIsSaving}/>}
                {createFlowStep === MODES.CONTENT && <ContentEditor skipContentStep={skipContentStep} setSkipContentStep={setSkipContentStep} hideTrimButton />}
            </MainBlock>
            <Footer>
                {shouldShowButtons &&
                    <>
                        <CancelBtn onClick={() => {
                            navigate('/')
                        }}>Cancel</CancelBtn>
                        <Button id={'next-button'} data-cy="wizard-next-btn" variant={ButtonVariant.Primary} size={ButtonSize.Large}
                            label={!sequenceIsSaving ? 'Next' : <CentredDiv><Loader size='xs' /></CentredDiv>}
                            onClick={async () => await changeStep()}
                            disabled={!canProgressToNextStep()}
                        />
                    </>
                }
            </Footer>
        </CreateContainer>

    )

}

ProjectWizard.videoTypesSor = []
